import React from "react";

const Footer = () => (
    <div className="footer">
        <div className="footer-bulk">
            <div className="float-left">
                © Tanay Vardhan, 2021
            </div>
            <div className="float-right">
                <a href="mailto: tvardha2@illinois.edu">
                    <i className="far fa-envelope" />
                </a> tvardha2 [at] illinois [dot] edu
            </div>
        </div>


    </div>
)

export default Footer