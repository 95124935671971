import React, { Component } from 'react';

class NavBar extends Component {

    render() {
        var leftElement;
        if (!this.props.showHomeButton)
            leftElement = "";
        else
            leftElement = <a href="/"><i className="fas fa-home"></i>Home</a>;
        return (
        <div className="nav">

            <div className="single-link">
            {leftElement}
            </div>
            <div className="link-box">
                {
                    links.map((link, key) => (
                        <div className="link" key={key}>
                            <a href={link.link} rel="noopener noreferrer" target="_blank"><i className={link.icon}/>{link.title}</a>
                        </div>
                    ))
                }
            </div>
        </div>
        );
    }
  }


  const links = [
      {
        "title": "GitHub",
        "icon": "fab fa-github",
        "link": "https://github.com/tanayv"
      },
      {
        "title": "Dribbble",
        "icon": "fab fa-dribbble",
        "link": "https://dribbble.com/tanayv1"
      },
      {
        "title": "Photos",
        "icon": "fas fa-camera-retro",
        "link": "https://vsco.co/tanayv/"
      },
      
  ]
  
  export default NavBar;